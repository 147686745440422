import { FC, PropsWithChildren, useContext, useRef } from "react";
import { createKeycloakInstance } from "../../../utils/funcs/vendors/Keycloak/instance";
import { ControlTowerContext } from "./ControlTower.context";
import useMainMenu from "./useMainMenu";

interface ControlTowerProviderProps extends PropsWithChildren {};

const ControlTowerProvider: FC<ControlTowerProviderProps> = ({ children }) => {
  const keycloakInstance = useRef(createKeycloakInstance())
  const mainMenuQuery = useMainMenu();
  
  const isHydrated = mainMenuQuery.isFetched;
  
  // useEffect(() => {
  //   keycloakInstance.current.init();
  // }, []);
    
  return (
    <ControlTowerContext.Provider value={{
      auth: {
        login: async () => {
          await keycloakInstance.current.init({
            scope: 'groepsadmin-full-access',
          });
          await keycloakInstance.current.login({redirectUri: 'https://verhuur.gouwgent.be'})
          console.log(keycloakInstance.current.token)
        },
        isAuthenticated: false,
        token: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ5MnZ0TVVCSG01QnVsSV9iWGs0R0lpNVQtT1NvNnJWWjBrV2FLWlJSOGZFIn0',
        refreshToken: null
      },
      hydration: {
        isHydrated,
      },
      interface: {
        menus: {
          main: {items: mainMenuQuery.data || []}
        }
      }
    }}>
      {children}
    </ControlTowerContext.Provider>
  )
}

const useControlTower = () => {
  const context = useContext(ControlTowerContext)
  if (!context) {
    throw new Error("useControlTower must be used within a ControlTowerProvider");
  }
  
  return context;
};

export { useControlTower };
export default ControlTowerProvider;