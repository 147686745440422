import dayjs from 'dayjs';
import { FC } from 'react';
import styles from './Calendar.module.scss';

interface Props {};

const Calendar: FC<Props> = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {Array.from({ length: 7 }).map((_day, dayIndex) => {
          const date = dayjs().startOf('week').add(dayIndex, 'day');
          
          return (
            <div key={date.toISOString()} className={styles.day}>
              <div>
                <p className="text-gray-500">{date.format('dddd')}</p>
                <p className="text-lg">{ date.format('DD MMM') }</p>
              </div>
              <div className="bg-black px-2 py-0.5 rounded-full text-sm text-white w-fit">
                2 +
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Calendar;