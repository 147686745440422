import { createContext } from "react";
import { MenuItem } from "../../../types/general";

interface ControlTowerContext {
  auth: {
    login: () => Promise<void>,
    isAuthenticated: boolean,
    token: string | null,
    refreshToken: string | null,
  },
  hydration: {
    isHydrated: boolean,
  },
  interface: {
    menus: {
      main: {
        items: MenuItem[];
      }
    }
  }
}

export const ControlTowerContext = createContext<ControlTowerContext | null>(null);