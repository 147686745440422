import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Button from '../../basics/Button/Button';
import Icon from '../../basics/Icon/Icon';
import { AvailableIcons } from '../../basics/Icon/Icon.types';
import Calendar from '../../elements/Calendar/Calendar';
import Carousel, { CarouselRef } from '../../elements/Carousel/Carousel';
import Form from '../../elements/Form/Form';
import Input from '../../elements/Input/Input';
import TimeSelector from '../../elements/TimeSelector/TimeSelector';

const AddBookingDivider = () => {
  const [d, setD] = useState(false);
  
  if (d) {
    return (
      <div className="py-2 px-2 flex flex-row items-center gap-2">
        <Input name="timeslot" type="time" placeholder="Datum" />
        <div>
          <Icon name="arrow-right-line" />
        </div>
        <Input name="timeslot" type="time" placeholder="Datum" />
      </div>
    );
  }
  return (
    <button type="button" className="relative w-full group" onClick={() => setD(true)}>
      <div className="h-1" />
      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
        <div className="bg-white rounded-full aspect-square w-fit scale-95 group-hover:scale-125 border-2 border-gray-100">
          <Icon name="add-line" className="text-gray-500" />
        </div>
      </div>
    </button>
  )
}

const ActiveBooking = ({ date, locations }: { date: string, locations: string[] }) => {
  return (
    <>
      <div className="flex flex-row justify-between p-2 bg-gray-100 rounded-lg">
        {dayjs(date).format('HH:mm')}
        <span>{locations.join(', ')}</span>
      </div>
    </>
  )
}

const TimeSlotSelector = () => {
  const methods = useFormContext()
  const dateValue = useWatch({ name: 'date' });
  
  if (!dateValue) return null;
  return (
    <div className="mt-6 px-6">
      <h4 className="text-xl mb-4">Boekingen</h4>
      <div className="flex flex-col">
        {Array.from({ length: 24 }).map((_, i) => (
          <div className="flex flex-row items-center gap-4 py-2">
            <span className="text-gray-300">{dayjs(dateValue).hour(i).minute(0).format('HH:mm')}</span>
            <div className="border-b border-gray-200 flex-1" />
          </div>
        ))}
      </div>
    </div>
  )
}

const BookingPlace = ({ title, icon, value }: { title: string; icon: AvailableIcons; value: string }) => {
  const methods = useFormContext();
  const checkedLocations = useWatch({ name: 'locations' });

  const isChecked = checkedLocations?.includes?.(value);
  const iconName = isChecked ? 'checkbox-circle-fill' : 'checkbox-circle-line';

  return (
    <label className="col-span-1 border border-gray-300 rounded-lg p-4 flex gap-4 justify-between">
      <div>
        <Icon name={icon} />
        <p className="text-lg">{ title }</p>
      </div>
      <Icon name={iconName} className={classNames(
        'self-start text-gray-300',
        'text-red-950'
      )} />
      <input type="checkbox" value={value} {...methods.register('locations')} className="hidden" />
    </label>
  )
}

interface Props {};

const LocationBookingPage: FC<Props> = () => {
  const carouselRef = useRef<CarouselRef>(null);
  
  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      <div className="w-96">
        <div className="mb-8">
          <h3 className="text-2xl text-gray-600">Huur ons gouwhuis</h3>
          <p>Je had graag ons gouwhuis gebruikt voor je vorming, groepsraad of iets anders? Dat kan!</p>
        </div>
        <div className="max-h-[80vh] overflow-y-auto border border-gray-200 rounded-xl">
          <Form>
            <Carousel ref={carouselRef}>
              <div className="p-6">
                <h4 className="text-xl mb-6">Wie ben je?</h4>
                <div className="flex flex-col gap-3">
                  <Input name="firstName" placeholder="Voornaam" />
                  <Input name="lastName" placeholder="Achternaam" />
                  <Input name="group" placeholder="Groep" />
                </div>
              </div>
              <div className="p-6">
                <h4 className="text-xl mb-6">Wat wil je boeken?</h4>
                <div className="grid grid-cols-2 gap-4">
                  <BookingPlace value="ground" icon="projector-2-line" title="Beneden" />
                  <BookingPlace value="first" icon="tv-2-line" title="Boven" />
                  <BookingPlace value="cellar" icon="stairs-line" title="Kelder" />
                </div>
              </div>
              <div className="py-6">
                <div className="px-6">
                  <h4 className="text-xl mb-4">Wanneer wil je boeken</h4>
                  <Input name="date" type="date" placeholder="Datum" />
                </div>
                <div className="mt-6">
                  <Calendar />
                </div>
                <TimeSelector />
                <TimeSlotSelector />
              </div>
              <div className="p-6">
                <h4 className="text-xl mb-6">Wat voor activiteit?</h4>
                <div className="flex flex-col gap-3">
                  <Input name="title" placeholder="Gelegenheid" />
                </div>
              </div>
            </Carousel>
          </Form>
        </div>
        <div className="mt-4 flex flex-row justify-between">
          <Button type="button" onClick={() => carouselRef.current?.previous()} iconAfter="arrow-left-line" />
          <Button type="button" onClick={() => carouselRef.current?.next()} iconAfter="arrow-right-line">Volgende</Button>
        </div>
      </div>
    </div>
  )
}

export default LocationBookingPage;