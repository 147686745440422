import { FC, useState } from 'react';

interface Props {};

const TimeSelector: FC<Props> = () => {
  const [canDrag, setCanDrag] = useState(false);
  const [offset, setOffset] = useState(0);
  
  return (
    <div onMouseOver={event => {
      console.log(event.movementX)
      if (canDrag) {
        setOffset(event.movementX);
      }
    }}>
      <div 
        className="h-16 w-4 bg-red-500"
        style={{ transform: `translateX(${offset}px)` }}
        onMouseDown={() => setCanDrag(true)}
        onMouseUp={() => setCanDrag(false)}
        
      />
    </div>
  )
}

export default TimeSelector;