import axios from 'axios';
import { FC } from 'react';
import { useQuery } from 'react-query';
import Button from '../../../../components/basics/Button/Button';
import { useControlTower } from '../../../../state/contexts/ControlTower/ControlTower';

interface Props {};

const InsuranceIndexPage: FC<Props> = () => {
  const controlTower = useControlTower();
  const query = useQuery({
    queryKey: ['modules', 'insurance', 'user'],
    queryFn: async () => {
      return (await axios('https://verzekeringen.scoutsengidsenvlaanderen.be/api/user', {
        headers: {
          Authorization: `Bearer ${controlTower.auth.token}`
        }
      })).data
    }
  })
  
  console.log('Query', query);
  
  return (
    <div>
      <Button onClick={() => {
        console.log('Login', controlTower.auth.login);
        controlTower.auth.login()
      }}>Login</Button>
    </div>
  )
}

export default InsuranceIndexPage;