import { FC, FormHTMLAttributes } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface Props extends FormHTMLAttributes<HTMLFormElement> {
  defaultValues?: Record<string, any>;
};

const Form: FC<Props> = ({ defaultValues, ...props }) => {
  const methods = useForm({
    defaultValues,
  });
  
  return (
    <FormProvider {...methods}>
      <form {...props} />
    </FormProvider>
  )
}

export default Form;