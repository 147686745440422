import { getStoryblokApi } from "@storyblok/react";
import { useQuery } from "react-query";

const useStoryblokQuery = (slug: string) => {
  const storyblokApi = getStoryblokApi();

  return useQuery<any>({
    queryKey: ['stories', slug],
    queryFn: () => storyblokApi.get(`cdn/stories` + slug),
    select: (response) => response.data.story,
    retry: 1,
    refetchOnWindowFocus: false,
  })
}

export default useStoryblokQuery;