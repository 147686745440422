import { StoryblokComponent } from "@storyblok/react";
import classNames from "classnames";
import dayjs from "dayjs";
import { FC, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { useControlTower } from "../../../state/contexts/ControlTower/ControlTower";
import useStoryblokQuery from "../../../utils/hooks/useStoryblokQuery";
import Button from "../../basics/Button/Button";
import HydrationOverlay from "../../elements/HydrationOverlay/HydrationOverlay";
import BaseLayout from "../../layouts/BaseLayout/BaseLayout";
import styles from './ContentFromSlugPage.module.scss';

interface Props {};

const ContentFromSlugPage: FC<Props> = () => {
  const location = useLocation();
  const query = useStoryblokQuery(location.pathname);
  const story = query.data;
  const [isLoading] = useDebounce(query.isFetching || query.isLoading, 2000);
  const ref = useRef(location.pathname);
  const controlTower = useControlTower();
  
  if (query.isError) return (
    <BaseLayout menuItems={controlTower.interface.menus.main.items}>
       <div className="flex items-center justify-center h-full">
        <div className="flex flex-col items-center">
          <h2 className="text-center text-4xl">Oeps!</h2>
          <p className="text-center">Deze pagina bestaat niet {'(meer)'}. Probeer je het nog eens opnieuw?</p>
          <Button iconAfter="arrow-right-line" className="mt-4" to="/functies">Meer ontdekken over Gouw Gent</Button>
        </div>
       </div>
    </BaseLayout>
  )
  if (isLoading) {
    return (
      <BaseLayout showMenu={false} showFooter={false}>
        <HydrationOverlay />
      </BaseLayout>
    );
  }
  
  const headerImage = story?.content.header_image as any;
  const hasHeaderImage = !!headerImage?.filename;
  // const storyCreateDate = dayjs(story.created_at).format("DD MMM YYYY")
  const storyLastPublishDate = dayjs(story?.published_at).format("DD MMM YYYY")
  

  return (
    <BaseLayout menuItems={controlTower.interface.menus.main.items}>
      <div className={classNames(styles.wrapper, hasHeaderImage && styles.withHeaderImage)}>
        <div className={styles.pageHeader}>
          {hasHeaderImage && (
            <div className={styles.headerImageWrapper}>
              <img src={headerImage.filename} alt="header" className={styles.headerImage} />
            </div>
          )}
          <div className={styles.pageHeaderContent}>
            <h2 className={styles.pageTitle}>{ story?.name }</h2>
            <p className={styles.storyDate}>Laatst aangepast {storyLastPublishDate}</p>
          </div>
        </div>
        <div className={styles.content}>
          <StoryblokComponent blok={story?.content} />
        </div>
      </div>
    </BaseLayout>
  );
};

export default ContentFromSlugPage;
