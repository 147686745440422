import classNames from 'classnames';
import { FC } from 'react';
import Icon from '../../Icon/Icon';
import { BaseButtonProps } from './BaseButon.types';
import styles from './BaseButton.module.scss';

interface Props extends BaseButtonProps {};

const BaseButton: FC<Props> = ({ variant = 'outline', iconAfter, children, ...otherProps }) => {
  return (
    <div 
      className={classNames(styles.base, styles[variant])} 
      {...otherProps}
    >
      {!!children && <span>{ children }</span>}
      {iconAfter && <Icon name={iconAfter} />}
    </div>
  )
}

export default BaseButton;