import classNames from 'classnames';
import { FC, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Input.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
};

const Input: FC<Props> = ({ className, name, ...props }) => {
  const methods = useFormContext();
  
  return (
    <input 
      {...props} 
      {...methods.register(name)} 
      className={classNames(styles.controller, className)} 
    />
  )
}

export default Input;