import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FC } from 'react';
import { storyblokUrlParser } from '../../../utils/funcs/parsers';
import BaseButton from '../../basics/Button/BaseButton/BaseButton';

interface Props {
  blok: SbBlokData;
};

const ButtonBlock: FC<Props> = ({ blok }) => {
  const label = blok?.label as string;
  const linkData = blok?.href as any;
  const target = (linkData?.target || '_self') as string;

  const href = storyblokUrlParser(blok.href);
  
  return (
    <div
      {...storyblokEditable(blok)} 
      className="max-w-4xl mx-auto"
      key={blok._uid} data-test="button"
    >
      <a href={href} target={target}>
        <BaseButton iconAfter="arrow-right-line">
          { label }
        </BaseButton>
      </a>
    </div>
  )
}

export default ButtonBlock;