import { Children, forwardRef, PropsWithChildren, useCallback, useImperativeHandle, useState } from 'react';
import styles from './Carousel.module.scss';

export interface CarouselRef {
  scrollToIndex: (index: number) => void;
  next: () => void;
  previous: () => void;
}

interface Props extends PropsWithChildren {};

const Carousel= forwardRef<CarouselRef, Props>(({ children }, ref) => {
  const [currentPage, setPage] = useState(0);
  
  const pages = Children.toArray(children);
  const pageCount = pages.length;
  
  const bindElementScrollIndexHandler = useCallback((pageIndex: number) => (element: HTMLDivElement) => {
    if (pageIndex === currentPage) element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [currentPage]);
  
  useImperativeHandle(ref, () => ({
    scrollToIndex: (index: number) => {
      setPage(index);
    },
    next: () => {
      if (currentPage < pageCount - 1) setPage((prev) => prev + 1);
    },
    previous: () => {
      if (currentPage > 0) setPage((prev) => prev - 1);
    }
  }), [currentPage]);
  
    
  return (
    <div className={styles.track}>
      {pages.map((page, pageIndex) => (
        <div 
          key={pageIndex} 
          className={styles.page}
          ref={bindElementScrollIndexHandler(pageIndex)}
          style={{ height: pageIndex === currentPage ? undefined : 0 }}
        >
          { page }
        </div>
      ))}
    </div>
  )
})

export default Carousel;